import React, { useRef } from "react";
import Slider from 'react-slick';
import { SampleLightNextArrow, SampleLightPrevArrow } from "../../util/sagaHelpers";
import css from './SectionHero.module.css';
import '../../styles/autocomplete-theme-classic.module.css';

const settings = {
    speed: 800,
    slidesToShow: 6,
    arrows: true,
    infinite: true,
    dots: false,
    centerMode: false,
    slidesToScroll: 2,
    draggable: false,
    cssEase: 'linear',
    nextArrow: <SampleLightNextArrow />,
    prevArrow: <SampleLightPrevArrow />,
    responsive: [
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 4,
                speed: 600,
                infinite: true,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: true,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 920,
            settings: {
                slidesToShow: 2,
                infinite: true,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 460,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
    ],
};

const SectionCategories = props => {
    const {
        categories,
        onFetchCategories
    } = props;

    const myCategoryRef = useRef(null);

    return (
        <div className={css.coverSlider} ref={myCategoryRef}>
            <Slider {...settings} className={css.swiperSlide}>
                {Array.isArray(categories) && categories.map(l => {
                    const imageURL = Object.hasOwn(l, 'image') ? l.image : null;
                    const updatedURL = imageURL !== null ? imageURL?.split('upload/')?.join('upload/f_auto/q_auto/') : null;
                    return (
                        <div
                            className={css.imageBack}
                            key={l.id}
                            onClick={() =>
                                onFetchCategories({
                                    type: 'subCategories',
                                    parentIds: [l.id],
                                    clearPrev: true,
                                }).then(() => {
                                    myCategoryRef.current.scrollIntoView({ behavior: 'smooth' });
                                })
                            }
                        >
                            <div key={l} className={css.imageBack}>
                                {/* <div className={css.roundIcon} style={{ backgroundImage: `url(${l.image})` }}>
                                    <div className={css.textName}>{l.label}</div>
                                </div> */}
                                <div className={css.roundIcon} style={{ backgroundImage: `url(${updatedURL})` }}>
                                    <div className={css.textName}>{l.label}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    )
};

export default SectionCategories;